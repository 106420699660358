import Axios from 'axios'
import Common from '../utils/common'
import {
  default as Config,
  default as Configuration,
} from '../utils/configuration'
import { getData, postData } from '../utils/data'

const languageId = localStorage.getItem(Config.tokenLanguage)
const merchantId = localStorage.getItem(Config.merchantId)
const tokenId = localStorage.getItem(Configuration.userCookie)
const userId = localStorage.getItem(Config.userId)
const api_url = Config.url_api + '/Command/SendSync'

export const createOrder = (data) => {
  return postData
    .sendCommand('CustomerRelationshipManagement', 'CreateOrder', data)
    .then(() => {
      const href =
        'https://izzilearn.izzipayment.com?id=' +
        data.Id +
        '&urlReturn=' +
        encodeURI(window.location.origin) +
        '&tokenId=' +
        localStorage.getItem(Configuration.userCookie)
      window.location.replace(href)
    })
}

export const createOrderMerchantPackageSale = (body) => {
  const stringCommandName = 'CreateOrderMerchantPackageSale'
  const stringDomain = 'Sale'
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(body),
    ModifiedBy: localStorage.getItem(Config.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10,
  }
  return Axios.post(api_url, dataSend)
}

export const closePayment = (id) => {
  var body = {
    Id: id,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: userId,
  }
  const dataSend = {
    CommandName: 'ClosePayment',
    Domain: 'Payment',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const failPayment = (id) => {
  var body = {
    Id: id,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: userId,
  }
  const dataSend = {
    CommandName: 'FailPayment',
    Domain: 'Payment',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const processDelivery = (value) => {
  const dataSend = {
    CommandName: 'DeliveryProcess',
    Domain: 'Delivery',
    Content: JSON.stringify(value),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}


export const cancelOrder = (cancelOrderDTO) => {
  const stringCommandName = 'RejectOrder'
  const stringDomain = 'Order'
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(cancelOrderDTO),
    ModifiedBy: localStorage.getItem(Config.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10,
  }
  return Common.sendSyncCommand2(dataSend)
}

export const getOrders = (option) => {
  const limit = option.limit === undefined ? 50 : option.limit
  const offset = option.offset === undefined ? 0 : option.offset
  const order = option.order === undefined ? 'desc' : option.order
  const sort = option.sort === undefined ? 'createdDate' : option.sort
  const keyword = option.keyword === undefined ? '' : option.keyword

  var query = `
    {
      orderconfirmeds(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
        ,keyword:"${keyword}"                
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,${option.allStatus ? `allStatus:${option.allStatus}` : ''}
         , ${
           option.from && option.to
             ? `from:"${option.from.toLocaleString()}",to:"${option.to.toLocaleString()}"`
             : ''
         }
       })
        {
              items {
                allStatus,
                grandTotal,
                id,
                code,
                createdDate,
                grandTotal,
                subTotal,
                paymentAmount,
                shippingAmount,
                paymentId,
                giftCodeAmount,
                promotionAmount,
                paymentAllStatus,
                paymentMethodName,
                note,
                 userName,
                userMobile,
                userEmail,
                
                payments {
                  allStatus,
                  amount,
                  code,
                  id, note, paymentMethodId, paymentMethodName, referenceCode, targetId
                },
                delivery{
                  addressDetail, trackingCode,amount,allStatus,supplierNote,id,note,receiverMobile,receiverName
                },
                orderLines {
                  id, targetName, originalPrice, price, quantity, total
                }, 
                user {
                  id, email, lastName, mobile, avatar, name
                },
              },
             
              totalCount
        }
      }
    `
  return Common.getDataGraphQl(query)
}

export const getOrder = (id, merchantId) => {
  return getData.getDataGraphQl(`
  {
    order(param:{id:"${id}",tokenUser:"${localStorage.getItem(
    Configuration.userCookie
  )}", merchantId:"${merchantId}", languageId:"${localStorage.getItem(
    Configuration.tokenLanguage
  )}"})
    {
      affiliateCommission,
      affiliateName,
      allStatus,
      code,
      createdBy,
      createdDate,
      createdTime,
      customerId,
      delivery {
        id
        amount
      },
      giftCodeAmount,
      grandTotal,
      id,
      merchantId,
      modifiedBy,
      modifiedDate,
      note,
      orderLines {
        id,
        price,
        quantity,
        originalPrice,
        targetName
        targetId
        total
        orderId
        discountAmount
      },
      paymentAllStatus,
      paymentAmount,
      paymentId,
      paymentMethodName,
      payments {
        id
        allStatus,
        amount
      },
      promotionAmount,
      subTotal,
      user {
        id,
        name,
        email
      },
      userEmail, 
      userMobile,
      userName
      tickets {
        id,
        createdName
        subDescription
        createdDate
      }
    }
  }
  `)
}

export const getDebtOrders = (option) => {
  const limit = 10000 // = option.limit === undefined ? 50 : option.limit;
  const offset = 0 // = option.offset === undefined ? 0 : option.offset;
  const order = option.order === undefined ? 'desc' : option.order
  const sort = option.sort === undefined ? 'createdDate' : option.sort
  const keyword = option.keyword === undefined ? '' : option.keyword

  var query = `
    {
      debtorders(param:{        
        limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
        ,tokenUser: "${tokenId}"
        ,keyword:"${keyword}"                
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,${option.allStatus ? `allStatus:${option.allStatus}` : ''}
         , ${
           option.from && option.to
             ? `from:"${option.from.toLocaleString()}",to:"${option.to.toLocaleString()}"`
             : ''
         }
       })
        {
              items {
                grandTotal,
                userName,
                userMobile,
                userEmail,
                customerId,
                createdDate,
                payments {
                  allStatus,
                  amount,
                  code,                 
createdDate,
                  orderCode,
                  id, note, paymentMethodId, paymentMethodName, referenceCode, targetId
                }
              },
             
              totalCount
        }
      }
    `
  return Common.getDataGraphQl(query)
}

export const getAffiliateOrders = (
  textSearch,
  allStatus,
  sort,
  order,
  limit,
  offset
) => {
  const languageId = localStorage.getItem(Config.languageId)
  const merchantId = localStorage.getItem(Config.merchantId)
  const tokenId = localStorage.getItem(window.config.localStorageTokenId)

  var query = `
    {
      affiliateorders(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
        ,keyword:"${textSearch}"        
        ,tokenUser:"${tokenId}"
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,${allStatus ? `allStatus:${allStatus}` : ''}
       })
        {
              items {
                allStatus,
                grandTotal,
                id,
                code,
                createdDate,
                grandTotal,
                subTotal,
                paymentAmount,
                giftCodeAmount,
                promotionAmount,
                paymentMethodName,
                userName,
                userMobile,
                userEmail,
                note,
                payments {
                  allStatus,
                  amount,
                  code,
                  id, note, paymentMethodId, paymentMethodName, referenceCode, targetId
                },
                delivery{
                  addressDetail, trackingCode,amount
                },
                orderLines {
                  id, targetName, originalPrice, price, quantity, total
                }, 
                user {
                  id, email, lastName, mobile
                },
              },
             
              totalCount
        }
      }
    `
  return Common.getDataGraphQl(query)
}

export const getOrderDetail = (orderId) => {
  const languageId = localStorage.getItem(Config.languageId)
  const merchantId = localStorage.getItem(Config.merchantId)
  var query = `
    {
        orders(param:{id:${orderId}
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
       })
        {
              items {
                id,
                code,
                createdDate,
                grandTotal,
                orderLines {
                  targetName
                }
              }
              ,totalCount
        }
      }
    `
  return Common.getDataGraphQl(query)
}

export const accept = (id) => {
  const body = {
    Id: id,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: localStorage.getItem('userId'),
  }

  const dataSend = {
    CommandName: 'AcceptOrder',
    Domain: 'Sale',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }
  return Axios.post(api_url, dataSend)
}

export const reject = (id) => {
  const body = {
    Id: id,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: localStorage.getItem('userId'),
  }

  const dataSend = {
    CommandName: 'RejectOrder',
    Domain: 'Sale',
    Content: JSON.stringify(body),
    TimeOutSecond: 7,
  }

  return Axios.post(api_url, dataSend)
}

export const createOrderCardProductSale = ({ productId, customerId }) => {
  return postData.sendCommand('Sale', 'CreateOrderCardProductSale', {
    Id: Common.guid(),
    MerchantId: merchantId,
    ProductId: productId,
    CustomerId: customerId,
    CreatedDate: Common.getCurrentDate(),
    CreatedBy: localStorage.getItem('userId'),
  })
}

export const createOrderCodProductSale = ({ productId, customerIds, categoryIds }) => {
  return postData.sendCommand('Sale', 'CreateOrderCodProductSale', {
    Id: Common.guid(),
    MerchantId: merchantId,
    ProductId: productId,
    CustomerIds: customerIds,
    CategoryIds: categoryIds,    
    ActionBy: localStorage.getItem('userId'),
  })
}
