import Common from "../utils/common";
import Configuration from "../utils/configuration";
import { getData } from "../utils/data";

const merchantId = localStorage.getItem(Configuration.merchantId);

export const getMerchant = () => {
  return getData.getDataGraphQl(`
            {
                merchant(param:{id:"${merchantId}"})
                {
                  code,
                  configuration,
                  createdBy,
                  createdDate,
                  googleAnalytics,
                  themeWebInstalled,
                  expiredTime
        }
                }
              
            `);
};

export const getSubMerchants = (options) => {
  if (options === undefined) options = {};
  if (options.merchantId === undefined) options.merchantId = merchantId;
  if (options.limit === undefined) options.limit = 1000000;
  if (options.offset === undefined) options.offset = 0;
  if (options.order === undefined) options.order = "desc";
  if (options.sort === undefined) options.sort = "createdDate";
  if (options.type === undefined) options.type = Configuration.ProductTypes.Undefined;
  options.keyword = options.keyword === undefined ? "" : options.keyword;
  const categoryIds =
    options.categoryIds === undefined ||
    options.categoryIds === null ||
    options.categoryIds.length === 0
      ? ""
      : `categoryIds:[${options.categoryIds.map((val) => `"${val}"`).join(",")}]`;

  return getData.getDataGraphQl(`
        {
            submerchants(param:{limit:${options.limit},offset:${options.offset},order:"${options.order}",sort:"${options.sort}"
            ,merchantId:"${options.merchantId}", keyword:"${options.keyword}"})
            {
              items{
                id,
                code,
              },
              totalCount,
              success,
              message
            }
        }
        `);
};
