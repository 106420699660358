import Common from "./common";
import text from "./text";

const Configuration = {
  googleMapApiKey: "AIzaSyCotFeTTdmEzjeVdx1_eAI4-mkfy73jgd8",
  // merchantLocal: 'geetonline', // 'labo',
  // merchantLocal: "ducddbk",y
  merchantLocal: "vpass",
  turnOffCheckExpire: true,
  // url_api: "http://192.168.1.4:8088",
  url_api: "https://apicommand.izzi.asia",
  merchantCode: "merchantCode",
  languageCode: "langauageCode",
  userCookie: "tokenId",
  userId: "userId",
  merchantId: "merchantId",
  tokenLanguage: "cmsLanguage",
  tokenNewMerchant: "newMerchant",
  // url_graphQl: "http://192.168.1.4:8080/graphql/",
  //url_graphQl: "https://apicms.izzi.asia/graphql/",
  url_graphQl: "https://apicms.izzi.asia/graphql/",
  // 'https://apicms.izzi.asia/graphql/', 'http://graphqlP.labo.io/graphql',
  // url_graphQl: "http://localhost:8080/graphql/", //'http://graphqlP.labo.io/graphql',
  //url_graphQl: 'http://localhost:5000/graphql/',
  languageVi: "838aef56-78bb-11e6-b5a6-00155d582814",
  languageEn: "e3509252-c42d-4ae5-9779-d4805a687a8e",
  // image_url: 'http://localhost:8000/',
  // image_upload_url: 'http://localhost:8088/Image/UploadFile',
  image_url: "https://static.izzi.asia",
  // API_COMMAND_URL: "https://192.168.1.4:8088",
  //API_COMMAND_URL: "https://apicommand.izzi.asia",
  API_COMMAND_URL: "https://apicommand.izzi.asia",
  image_upload_url: "https://apicommand.izzi.asia/Image/UploadFile",
  THEMEWEB_MERCHANT_ID: "ce715a3e-860f-41cb-b4d9-88ca7f32bf10",
  MAX_FILE_SIZE: 512000,
  //MAX_FILE_SIZE_BOOK: 4194304, //4MB
  MAX_FILE_SIZE_BOOK: 104857600, //4MB
  sampleUserExcelLink: "https://static.izzi.asia/TemplateImport/TemplateImportUser.xlsx",
  domain: ".labo.io",
  https: "https://",
  helpDeskMerchantId: "74d84561-4925-4b13-9e5c-0137bea8afcd",
  libraryMerchantId: "ebd5cacf-c5f5-4ad2-975d-7ed5a21f45de",
  izziCmsMerchantId: "46330eee-eaff-47e6-a4b8-53aa116b3eb7",
  defaultImg: "https://static.izzi.asia/images/2020/3/26/2003268324_defaultimage.jpg",
  defaultUserImg: "https://static.izzi.asia/images/2023/5/31/2305317476_userplaceholder.jpg",
  iframePreview: "https://" + localStorage.getItem("merchantCode") + ".labo.io",
  linkNav: {
    categories: function ({ id, name }) {
      return `/danh-sach-bai-viet/${id}/${Common.rewriteUrl(name)}`;
    },
    articles: function ({ id, name }) {
      return `/bai-viet/${id}/${Common.rewriteUrl(name)}`;
    },
  },
  merchantPackageType: {
    MerchantPackageCms: 256,
    MerchantPackageElearn: 1024,
  },

  SEGMENT: {
    CONDITION_TYPE: {
      BOOL: 0,
      STRING: 1,
      NUMBER: 2,
      CONDITION: 3, //And/or
      VALUE: 4,
      REFERENCE: 5,
    },
    CONDITION_OPERATOR: {
      NONE: 0,
      //condition
      AND: 1,
      OR: 2,
      //Common
      EQUAL: 3,
      NOT_EQUAL: 4,
      //Number
      LESS_THAN: 5,
      LESS_THAN_OR_EQUAL: 6,
      GREATER_THAN: 7,
      GREATER_THAN_OR_EQUAL: 8,
      //String
      CONTAINS: 9,
      NOT_CONTAINS: 10,
      START_WITH: 11,
      NOT_START_WITH: 12,
      END_WITH: 13,
      NOT_END_WITH: 14,
    },
  },

  attributeConfiguration: {
    target: {
      PRODUCT: 1,
      ADDRESS: 2,
      USER: 3,
      CATEGORY: 4,
      CLASSROOM: 5,
      Article: 6,
    },
    dataType: {
      VARCHAR: 1,
      TEXT: 2,
      INT: 3,
      DECIMAL: 4,
      DATETIME: 5,
    },
    displayType: {
      TEXTBOX: 1,
      RADIO: 2,
      CHECKBOX: 3,
      SELECTION: 4,
      MUILTISECTION: 5,
      TEXTAREA: 6,
      DATETIME: 7,
    },
  },
  courseType: {
    DEFAULT: {
      value: 1,
      name: "Mặc định",
    },
    SEQUENCE: {
      value: 64,
      name: "Tuần tự",
    },
    TIME_TABLE: {
      value: 4,
      name: "Điều kiện thời gian",
    },
    CONDITION_QUIZ: {
      value: 16,
      name: "Điều kiện kiểm tra",
    },
  },
  quizType: {
    DEFAULT: 1,
    CLIP: 4,
  },
  statusContract: {
    CREATED: 1,
    PROCESS: 32,
    TIMEOUT: 256,
    CUSTOMER_CONFIRM: 1024,
    MERCHANT_CONFIRM: 4096,
    FAILED: 32768,
    CLOSED: 1048576,
  },
  statusOrder: {
    SUCCESS: 1048576,
    FAIL: 32768,
    PROCESS: 32,
    DEFAULT: 1,
  },
  statusDelivery: {
    SUCCESS: 1048576,
    FAIL: 32768,
    TIMEOUT: 1024,
    PROCESS: 32,
    DEFAULT: 1,
  },
  statusPayment: {
    REFUNDED: 1073741824,
    SUCCESS: 33554432,
    FAIL: 1048576,
    TIMEOUT: 32768,
    PROCESS: 32,
    DEFAULT: 1,
  },
  typeActionForm: {
    emailResult: 1,
    subscribeToAList: Math.pow(2, 2),
    rawData: Math.pow(2, 4),
    emailReturn: Math.pow(2, 6),
    useEvoucher: Math.pow(2, 8),
    smsReturn: Math.pow(2, 10),
    exportEvoucher: Math.pow(2, 12),
  },
  typeOnSubmit: {
    ShowThankYou: 1,
    OpenUrl: Math.pow(2, 5),
  },

  categoryTypes: {
    ADDRESS: 1,
    ARTICLE: 2,
    PRODUCT: 3,
    CUSTOMER: 4,
    QUIZ: 5,
    BOOK: 8,
    STAFF: 9,
    CART: 10,
    HOME_PAGE: 32,
    IMAGE: 64,
    TEMPLATE: 128,
    MENU: 256,
    COURSE: 512,
    ORDER: 1024,
    ROOM_BOOKING: 2048,
    TAGS: 32768,
    THEME_WEB: 4096,
    THEME_WEB_SECTION: 16384,
    THEME_WEB_TEMPLATE: 8192,
    MEMBERSHIP: 131072,
    KANBAN: 262144,
    CMS_MENU: 524288,
    CMS_MENU_MEMBER: 260,
    MERCHANT_TYPE: 1048576,
    MERCHANT_TYPE_CODE: 2097152,
    PRODUCT_FEE: 8388608,
    USER_RIGHT: 536870912,
    MEETING: 1073741824,
  },
  FileTypes: {
    UNDEFINED: 1,
    BOOK: 4,
    VIDEO: 16,
    PDF: 64,
    IMAGE: 256,
  },
  UserTypes: {
    DEFAULT: Math.pow(2, 0),
    AFFILIATE: Math.pow(2, 2),
    AUTHOR: Math.pow(2, 6),
    MOD: Math.pow(2, 15),
    ADMIN: Math.pow(2, 20),
  },
  StorageTypes: {
    OWNER: 1,
    YOUTUBE: 4,
    VIMEO: 16,
    GOOGLE_DRIVE: 64,
  },
  ProductTypes: {
    //Undefined: 0,
    None: 1,
    EVoucher: 8,
    Course: 32,
    ExchangePoint: 64,
    MerchantPackage: 256,
    RoomBooking: 2048,
    OfficeBooking: 4096,
    Vacation: 16384,
    Card: 32768,
    VacationExchange: 131072,
    Fee: 262144,
    MemberPackage: 2097152,
    TimeShare: 16777216,
    VacationPremiumPass: 4194304, //22
  },
  AddressTypes: {
    Default: 1,
    Event: 1 << 2,
    Restaurant: 1 << 5,
    Warehouse: 1 << 10,
    School: 1 << 15,
    Resort: 1 << 17, //131,072
  },
  ClassRoomTypes: {
    Default: 1,
  },
  courseTypes: {
    DEFAULT: 1,
    SEQUENCE: 64,
    TIME_TABLE: 4,
    CONDITION_QUIZ: 16,
  },
  ArticleTypes: {
    Default: 0,
    Book: 5,
    Slide: 7,

    Quiz: 9,
    QuizQuestion: 10,
    QuizQuestionAnswer: 11,
    BoardOpening: 12,
    Survey: 15,
    SurveyQuestion: 16,
    SurveyQuestionAnswer: 17,
    SurveyQuestionAnswerEnableCustom: 18,
    Video: 32,
    Ticket: 64,
    ThemeWebTempate: 8192,

    Certificate: 128,
    Lesson: 256,
    //OrderComment : 512,
  },
  // articleType: {
  //   ARTICLE: {
  //     type: 0,
  //     categoryType: 2,
  //     commandCreate: "CreateArticleVersion01",
  //     commandUpdate: "UpdateInformationArticleVersion01"
  //   },
  //   BOOK: {
  //     type: 5,
  //     categoryType: 8,
  //     // categoryType: 8,
  //     commandCreate: "CreateBookArticle",
  //     commandUpdate: "UpdateInformationBookArticle"
  //   },
  //   SLIDE: {
  //     type: 7,
  //     categoryType: 512,// Configuration.categoryTypes.COURSE,
  //     // categoryType: 16,
  //     commandCreate: "CreateSlideArticle",
  //     commandUpdate: "UpdateInformationSlideArticle"
  //   },
  //   VIDEO: {
  //     type: 32,
  //     categoryType: 512,// Configuration.categoryTypes.COURSE,
  //     // categoryType: 18,
  //     commandCreate: "CreateVideoArticle",
  //     commandUpdate: "UpdateInformationVideoArticle"
  //   },
  // },
  pages: function ({ id, name }) {
    return `/pages/${id}/${Common.rewriteUrl(name)}`;
  },

  articleType: {
    ARTICLE: {
      type: 0,
      categoryType: 2,
      commandCreate: "CreateArticleVersion01",
      commandUpdate: "UpdateInformationArticleVersion01",
      name: "Bài viết",
    },
    BOOK: {
      type: 5,
      categoryType: 8,
      // categoryType: 8,
      commandCreate: "CreateBookArticle",
      commandUpdate: "UpdateInformationBookArticle",
      name: "Sách",
    },
    SLIDE: {
      type: 7,
      categoryType: 512, // Configuration.categoryTypes.COURSE,
      // categoryType: 16,
      commandCreate: "CreateSlideArticle",
      commandUpdate: "UpdateInformationSlideArticle",
      name: "Slide",
    },
    VIDEO: {
      type: 32,
      categoryType: 512, // Configuration.categoryTypes.COURSE,
      // categoryType: 18,
      commandCreate: "CreateVideoArticle",
      commandUpdate: "UpdateInformationVideoArticle",
      name: "Video",
    },
    LESSON: {
      type: 256,
      categoryType: 512, // Configuration.categoryTypes.COURSE,
      // categoryType: 18,
      commandCreate: "CreateLessonArticle",
      commandUpdate: "UpdateLessonArticle",
      name: "Lesson",
    },
    ProductTypes: {
      Undefined: 0,
      None: 1,
      EVoucher: 8,
      Course: 32,
      MerchantPackage: 256,
      RoomBooking: 2048,
      OfficeBooking: 4096,
      Vacation: 16384,
    },
    THEME_WEB_TEMPLATE: {
      type: 8192,
      categoryType: 8192,
      name: "Template theme",
      // commandCreate: "CreateVideoArticle",
      // commandUpdate: "UpdateInformationVideoArticle",
    },
  },
  stateVideo: {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    VIDEO_CUED: 5,
  },
  answerType: {
    trueFalse: {
      type: Math.pow(2, 0),
      name: "Đúng/Sai",
    },
    multiChoice: {
      type: Math.pow(2, 1),
      name: "Chọn 1 đáp án đúng",
    },
    multiAnswer: {
      type: Math.pow(2, 2),
      name: "Chọn nhiều đáp án",
    },
    shortAnswer: {
      type: Math.pow(2, 3),
      name: "Câu hỏi tự luận",
    },
    fillInTheBank: {
      type: Math.pow(2, 4),
      name: "Điền vào chỗ trống",
    },
    matching: {
      type: Math.pow(2, 5),
      name: "Khớp câu hỏi & đáp án",
    },
  },
  questionType: [
    { type: Math.pow(2, 0), value: "trueFalse", text: "Đúng/Sai" },
    { type: Math.pow(2, 1), value: "multiChoice", text: "Chọn 1 đáp án đúng" },
    { type: Math.pow(2, 2), value: "multiAnswer", text: "Chọn nhiều đáp án" },
    { type: Math.pow(2, 3), value: "shortAnswer", text: "Câu hỏi tự luận" },
    {
      type: Math.pow(2, 4),
      value: "fillInTheBank",
      text: "Điền vào chỗ trống",
    },
    { type: Math.pow(2, 5), value: "matching", text: "Khớp câu hỏi & đáp án" },
  ],
  // questionType: {
  //     TF: "trueFalse",
  //     MC: "multiChoice",
  //     MA: "multiAnswer",
  //     SA: "shortAnswer",
  //     FIB: "fillInTheBank",
  //     Matching: "matching",
  // },
  merchantType: {
    normal: 1,
    full: 32,
    reward: 128,
    elearning: 512,
    content: 1024,
    admin: 2048,
    vacation: 4096,
    coworking: 8192,
  },
  templateType: {
    THEME: 10,
    EMAIL: 20,
    ARTICLE: 30,
    PRODUCT: 40,
    CONTRACT: 50,
    CERTIFICATE: 60,
    NOTIFICATION: 70,
  },
  editorConfiguration: {
    allowedContent: true,
    // extraPlugins: "language"
  },
  allLanguage: [
    {
      label: text("VIETNAMESE"),
      value: "838aef56-78bb-11e6-b5a6-00155d582814",
    },
    {
      label: text("ENGLISH"),
      value: "e3509252-c42d-4ae5-9779-d4805a687a8e",
    },
  ],
  scriptUrlCke: "https://static.izzi.asia/Templates/ckeditor/ckeditor.js",

  sectionsType: {
    HEADER: Math.pow(2, 0),
    SIDE_BAR_LEFT: Math.pow(2, 2),
    SIDE_BAR_RIGHT: Math.pow(2, 4),
    FOOTER: Math.pow(2, 6),
    BODY_HOME: Math.pow(2, 8),
    BODY_ARTICLES: Math.pow(2, 10),
    BODY_ARTICLE: Math.pow(2, 12),
    BODY_PRODUCTS: Math.pow(2, 14),
    BODY_PRODUCT: Math.pow(2, 16),
    BODY_PAGE: Math.pow(2, 18),
  },
  userType: {
    DEFAULT: Math.pow(2, 0),
    AFFILIATE: Math.pow(2, 2),
    // STAFF: Math.pow(2, 5),
    AUTHOR: Math.pow(2, 6),
    EDITOR: Math.pow(2, 8),
    SALE: Math.pow(2, 11),
    TO: Math.pow(2, 12),
    MANAGER: Math.pow(2, 13),
    MOD: Math.pow(2, 15),
    ADMIN: Math.pow(2, 20),
    SUPPLIER: Math.pow(2, 22),

  },
  routerEditor: [
    "categories",
    "product-categories",
    "product",
    "articles",
    "gallery",
    "image-categories",
    "product-categories",
  ],
  toast_information: {
    LOAD_DATA_SUCCESS: function (numRow, cate) {
      if (cate == undefined) cate = "";
      if (numRow == undefined) numRow = "";
      return `Tải thành công ${numRow} bản ghi ${cate} từ máy chủ`;
    },
    SUCCESS: "Thành công",
    LOAD_DATA_ERROR: "Tải dữ liệu không thành công",
    ERROR: function (error) {
      return `Có lỗi xảy ra: ${error + ""}`;
    },
    CHANGE_STATUS_SUCCESS: "Đổi trạng thái thành công",
  },
  GOONG_PLACE_API_KEY: "6RjmIw0Lhv59nNg7Tp4ceqmnhL4kgBIzhXAGq8Ri",
};

export default Configuration;
